import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';

export const SupplementaryText = styled.span`
    font-size: 10px;
    line-height: 12px;
    white-space: nowrap;
    color: var(--textColor);
    opacity: 50%;

    ${media.phone(`
        font-size: 12px;
        line-height: 14px;
    `)};

    ${media.tabletSmall(`
        font-size: 14px;
        line-height: 16px;
    `)};
`;
