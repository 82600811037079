import { darkTheme, lightTheme } from 'constants/styles/colors';
import 'constants/styles/global';
import { useStore } from 'effector-react';
import { Route, Routes } from 'react-router-dom';
import { projectStores } from 'stores/project';
import { Layout } from './components/Layout/styles';
import { mainPage, versionSinglePage } from './constants/routes';
import { appVersion } from './constants/services';
import { Main } from './pages/Main';
import { Single } from './pages/Single';
import { ApplicationProps } from './types/plugin';
import { ThemeEnum } from 'types/types';
import React from 'react';

const { projectData } = projectStores;

const App = ({ theme }: ApplicationProps) => {
    const { theme: projectTheme } = useStore(projectData);
    const chosenTheme = theme || projectTheme;
    const themeClass = chosenTheme === ThemeEnum.Dark ? darkTheme : lightTheme;

    return (
        <Layout className={`ivwt-plugin ${appVersion} ${themeClass}`}>
            <Routes>
                <Route element={<Main />} path={mainPage} />
                <Route element={<Single />} path={versionSinglePage} />
            </Routes>
        </Layout>
    );
};

export default App;
