import React from 'react';
import { StyledClickableWrapper } from './styles';
import { NoopClick } from 'types/global';
import { CrossIcon } from 'components/common/icons';

export interface CloseButtonProps extends NoopClick {}

export const CloseButton = ({ onClick }: CloseButtonProps) => (
    <StyledClickableWrapper onClick={() => onClick()}>
        <CrossIcon />
    </StyledClickableWrapper>
);
