import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';

export const BlockWrapper = styled.div`
    width: 100%;
    height: 195px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 2px solid var(--horizontalLineColor);

    ${media.phone(`
        padding-bottom: 20px;
        margin-bottom: 20px;
    `)};

    ${media.tablet(`
       padding-bottom: 24px;
       margin-bottom: 24px;
    `)};
`;
