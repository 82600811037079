import { styled } from '@linaria/react';
import { Paragraph } from 'components/common/typography/Paragraph/styles';
import { BlockWrapper } from 'components/wrappers/BlockWrapper/styles';
import { media } from 'constants/styles/media';

export const SinglePageTitle = styled.h2`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-right: 45px;
    color: var(--textColor);

    ${media.phone(`
        font-size: 16px;
        line-height: 19px;
    `)};

    ${media.phoneBig(`
        margin-right: 55px;
    `)};

    ${media.laptopSmall(`
        font-size: 24px;
        line-height: 29px;
    `)};
`;

export const SinglePageTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
`;

export const StyledBlockWrapper = styled(BlockWrapper)`
    height: fit-content;
`;

export const StyledParagraph = styled(Paragraph)`
    margin-top: 16px;

    ${media.laptopSmall(`
        font-size: 14px;
        line-height: 17px;
    `)};
`;
