import { styled } from '@linaria/react';
import { white, blue } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${white};
    padding: 12px 56px;
    border-radius: 6px;
    background-color: ${blue};
`;
