import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { modalEvents } from 'stores/modal';
import { Theme } from 'types/types';
import App from './App';
import { initEvents } from './stores/init';
import { IVWTInitParameters } from './types/plugin';

const { setInitialParameters } = initEvents;
const { toggleVisibility } = modalEvents;

if (process.env.NODE_ENV === 'production') {
    window.ivwt = {
        init: (parameters: IVWTInitParameters) => {
            const { selector, theme } = parameters;
            const rootElement = document.querySelector<HTMLElement>(selector);
            console.log('PROD_parameters', parameters);
            setInitialParameters(parameters);

            if (rootElement) {
                ReactDOM.render(
                    <StrictMode>
                        <HashRouter basename="/">
                            <App theme={theme as Theme} />
                        </HashRouter>
                    </StrictMode>,
                    rootElement
                );
            }
        },
        show: () => toggleVisibility(),
        hide: () => toggleVisibility()
    };
} else {
    window.ivwt = {
        init: (parameters: IVWTInitParameters) => {
            const { selector, theme } = parameters;
            const rootElement = document.querySelector<HTMLElement>(selector);
            console.log('DEV_parameters', parameters);
            setInitialParameters(parameters);

            if (rootElement) {
                ReactDOM.render(
                    <StrictMode>
                        <HashRouter basename="/">
                            <App theme={theme as Theme} />
                        </HashRouter>
                    </StrictMode>,
                    rootElement
                );
            }
        },
        show: () => toggleVisibility(),
        hide: () => toggleVisibility()
    };
}

let event = new Event('ivwt-plugin-init', { bubbles: true });
document.body.dispatchEvent(event);
