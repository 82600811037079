import { defaultInitValues } from 'constants/defaults';
import { createEvent, createStore } from 'effector';
import { IVWTInitParameters } from '../types/plugin';

const setInitialParameters = createEvent<IVWTInitParameters>();

const initialParametersStore = createStore<IVWTInitParameters>(defaultInitValues).on(
    setInitialParameters,
    (_, newState) => newState
);
//TODO remove when don't needed
//initialParametersStore.watch(state => console.log('initialParameters', state));

export const initStores = { initialParametersStore };
export const initEvents = { setInitialParameters };
