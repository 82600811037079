import { styled } from '@linaria/react';
import { black } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';

export const ClickableWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    color: ${black};
    border-radius: 4px;
    padding: 15px;
    background-color: transparent;
    transition: color 0.3s;
`;
