import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';

export const Title = styled.h1`
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-left: 15px;
    margin-right: 15px;
    color: var(--textColor);

    ${media.phone(`
        font-size: 18px;
        line-height: 21px;
    `)};

    ${media.phoneBig(`
        font-size: 20px;
        line-height: 23px;
    `)};

    ${media.laptopSmall(`
        font-size: 22px;
        line-height: 25px;
    `)};

    ${media.tabletSmall(`
        font-size: 24px;
        line-height: 28px;
    `)};
`;
