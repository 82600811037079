import { BaseButton } from 'components/common/buttons/BaseButton';
import { CloseButton } from 'components/common/buttons/CloseButton';
import { BackArrowIcon, WarningSign } from 'components/common/icons';
import { Title } from 'components/common/typography/Title/styles';
import { Pagination } from 'components/Pagination';
import { defaultPageIndex, defaultPageLimit, defaultTotalPages } from 'constants/defaults';
import { mainPage } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC, useRef, useState } from 'react';
import { modalEvents, modalStores } from 'stores/modal';
import { projectEffects, projectStores } from 'stores/project';
import { IsSinglePage } from 'types/versions';
import { defaultTitle } from './constants';
import {
    AbsoluteWrapper,
    ErrorText,
    ErrorWrapper,
    ImageWrapper,
    Modal,
    ModalBackground,
    ModalContentWrapper,
    PaginationWrapper,
    Span,
    StyledLink,
    TitleWrapper
} from './styles';

const { getProjectData } = projectEffects;
const { toggleVisibility } = modalEvents;
const { isModalVisible } = modalStores;
const { errors } = projectStores;

interface ModalWrapperProps extends IsSinglePage {
    title?: string;
    currentPage?: number;
    totalPages?: number;
}
export const ModalWrapper: FC<ModalWrapperProps> = ({
    children,
    title = defaultTitle,
    isSinglePage,
    currentPage = defaultPageIndex,
    totalPages = defaultTotalPages
}) => {
    const visible = useStore(isModalVisible);
    const { data, message, err } = useStore(errors);
    const [pageIndex, setPageIndex] = useState(currentPage);
    const componentRef = useRef<HTMLDivElement>(null);
    const isError = !!(data || message || err);

    if (!visible) return null;

    const onBackgroundClick = (event: React.MouseEvent) => {
        if (componentRef.current === event.target) {
            toggleVisibility();
        }
    };

    const onIndexChange = (index: number) => {
        setPageIndex(index);
        getProjectData({ page: index, limit: defaultPageLimit });
    };

    return (
        <ModalBackground ref={componentRef} onClick={onBackgroundClick}>
            <Modal>
                {isError ? (
                    <ErrorWrapper>
                        <AbsoluteWrapper>
                            <CloseButton onClick={toggleVisibility} />
                        </AbsoluteWrapper>

                        <ImageWrapper>
                            <WarningSign height="auto" width="100%" />
                        </ImageWrapper>

                        <ErrorText>
                            Something went wrong. <Span>Please, contact to the dev team.</Span>
                        </ErrorText>

                        <BaseButton onClick={toggleVisibility}>Ok</BaseButton>
                    </ErrorWrapper>
                ) : (
                    <>
                        <TitleWrapper>
                            {isSinglePage ? (
                                <StyledLink to={mainPage}>
                                    <BackArrowIcon />
                                </StyledLink>
                            ) : (
                                <Title>{title}</Title>
                            )}
                            <CloseButton onClick={toggleVisibility} />
                        </TitleWrapper>

                        <ModalContentWrapper isSinglePage={isSinglePage}>{children}</ModalContentWrapper>

                        {totalPages > 1 && !isSinglePage && (
                            <PaginationWrapper>
                                <Pagination
                                    currentIndex={pageIndex}
                                    totalPages={totalPages}
                                    onIndexChange={onIndexChange}
                                />
                            </PaginationWrapper>
                        )}
                    </>
                )}
            </Modal>
        </ModalBackground>
    );
};
