import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';

export const IconWrapper = styled.div`
    ${flexCenter}
    margin-left: 6px;
`;

export const TagText = styled.span`
    font-size: 8px;
    line-height: 10px;
    color: currentColor;

    ${media.phone(`
        font-size: 10px;
        line-height: 12px;
    `)};

    ${media.tabletSmall(`
       font-size: 12px;
       line-height: 14px;
    `)};
`;

export const StyledLink = styled.a`
    ${flexCenter};

    &:hover ${TagText} {
        text-decoration: underline;
    }
`;
