import { styled } from '@linaria/react';
import { ClickableWrapper } from 'components/wrappers/ClickableWrapper/styles';

export const StyledClickableWrapper = styled(ClickableWrapper)`
    color: var(--closeIconColor);

    &:hover {
        color: var(--closeIconHoverColor);
    }
`;
