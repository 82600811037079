import { DescriptionBlock } from 'components/blocks/DescriptionBlock';
import { SkeletonDescriptionBlock } from 'components/blocks/SkeletonDescriptionBlock';
import { ModalWrapper } from 'components/wrappers/ModalWrapper';
import { defaultQueryValues } from 'constants/defaults';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isFirstRequestEvents, isFirstRequestStores } from 'stores/initialize.isFirst.store';
import { projectEffects, projectStores } from 'stores/project';

const { getProjectData } = projectEffects;
const { projectData } = projectStores;
const { isFirstRequest } = isFirstRequestStores;
const { setIsFirstRequestToFalse } = isFirstRequestEvents;

export const Main = () => {
    const { items, /*itemsCount,*/ totalPages, currentPage } = useStore(projectData);
    const isFirst = useStore(isFirstRequest);
    const isLoading = useStore(getProjectData.pending);
    const blocksArray = ['first', 'second', 'third', 'fourth'];

    useEffect(() => {
        if (isFirst) {
            getProjectData(defaultQueryValues);
            setIsFirstRequestToFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalWrapper currentPage={currentPage} totalPages={totalPages}>
            {isLoading
                ? blocksArray.map(item => <SkeletonDescriptionBlock key={item} />)
                : items?.map(version => <DescriptionBlock key={version.id} {...version} />)}
        </ModalWrapper>
    );
};
