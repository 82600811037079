import React from 'react';
import {
    BlockWrapper,
    TagWrapper,
    TagsWrapper,
    HeaderWrapper,
    DateWrapper,
    DescriptionWrapper,
    TitleWrapper,
    VersionWrapper
} from './styles';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonDescriptionBlock = () => {
    const tagsArray = ['first', 'second', 'third', 'fourth', 'fifth'];

    return (
        <BlockWrapper>
            <SkeletonTheme baseColor="var(--skeletonBaseColor)" highlightColor="var(--skeletonHighlightColor)">
                <HeaderWrapper>
                    <TitleWrapper>
                        <Skeleton />
                    </TitleWrapper>

                    <VersionWrapper>
                        <Skeleton height="16px" />
                    </VersionWrapper>
                </HeaderWrapper>

                <DateWrapper>
                    <Skeleton />
                </DateWrapper>

                <TagsWrapper>
                    {tagsArray.map(tag => (
                        <TagWrapper key={tag}>
                            <Skeleton height="24px" />
                        </TagWrapper>
                    ))}
                </TagsWrapper>

                <DescriptionWrapper>
                    <Skeleton />
                </DescriptionWrapper>
            </SkeletonTheme>
        </BlockWrapper>
    );
};
