import React from 'react';
import { Badge } from 'components/Badge';
import { DescriptionTitle } from 'components/common/typography/DescriptionTitle';
import { DescriptionImages } from 'components/blocks/DescriptionImages';
import { Paragraph } from 'components/common/typography/Paragraph/styles';
import { Tag } from 'components/Tag';
import { lightBoxEvents } from 'stores/lightbox';
import { TextWrapper, UpdateBlockWrapper, UpdateTagsWrapper } from './styles';

const { show } = lightBoxEvents;

export interface UpdateDescriptionBlockProps extends IVWT.Update {}

export const UpdateDescriptionBlock = ({ title, description, type, tags, files }: UpdateDescriptionBlockProps) => (
    <UpdateBlockWrapper>
        {!!files.length && <DescriptionImages images={files} onOpenLightbox={() => show(files)} />}
        <TextWrapper>
            <DescriptionTitle isSinglePage>{title}</DescriptionTitle>

            {tags.length > 0 && (
                <UpdateTagsWrapper>
                    <Badge type={type} />

                    {tags.map(tag => (
                        <Tag key={`${tag.tag}-${tag.link}`} {...tag} />
                    ))}
                </UpdateTagsWrapper>
            )}

            <Paragraph>{description}</Paragraph>
        </TextWrapper>
    </UpdateBlockWrapper>
);
