import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';

export const BlockWrapper = styled.div`
    width: 100%;
    border-bottom: 2px solid var(--horizontalLineColor);
    margin-bottom: 24px;
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
`;

export const TitleWrapper = styled.div`
    width: 154px;

    ${media.phone(`
        width: 174px;
    `)};

    ${media.phoneBig(`
        width: 256px;
    `)};

    ${media.laptopSmall(`
        width: 318px;
    `)};
`;

export const VersionWrapper = styled.div`
    width: 56px;

    ${media.phone(`
        width: 70px;
    `)};

    ${media.phoneBig(`
        width: 78px;
    `)};
`;

export const DateWrapper = styled.div`
    width: 106px;
    margin-bottom: 10px;
`;

export const TagWrapper = styled.div`
    width: 70px;
    min-width: 70px;
    height: 24px;
    margin-right: 8px;
    margin-bottom: 4px;
`;

export const TagsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;
`;

export const DescriptionWrapper = styled.div`
    width: 100%;
    margin-bottom: 24px;
`;
