import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { NextArrow, PrevArrow } from 'components/common/icons';
import { Buttons, DescriptionImagesWrapper, IconButton, ImagesWrapper, ImageWrapper, StyledImage } from './styles';
import { defaultNavigation, slidesPerView } from './constants';
import 'swiper/css';

interface Props {
    images: IVWT.File[];
    onOpenLightbox?: () => void;
}

interface NavigationProperties {
    prevEl: HTMLButtonElement | null;
    nextEl: HTMLButtonElement | null;
}

export const DescriptionImages = ({ images, onOpenLightbox = () => {} }: Props) => {
    const navigationPrevRef = useRef<HTMLButtonElement>(null);
    const navigationNextRef = useRef<HTMLButtonElement>(null);
    const [navigation, setNavigation] = useState<NavigationProperties>(defaultNavigation);

    useEffect(() => {
        if (navigationPrevRef.current && navigationNextRef.current) {
            setNavigation({
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
            });
        }
    }, []);

    return (
        <DescriptionImagesWrapper>
            <ImagesWrapper onClick={onOpenLightbox}>
                <Swiper modules={[Navigation]} navigation={navigation} slidesPerView={slidesPerView} spaceBetween={10}>
                    {images.map(({ id, url, name }) => (
                        <SwiperSlide key={id}>
                            <ImageWrapper>
                                <StyledImage alt={name} src={url} />
                            </ImageWrapper>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </ImagesWrapper>

            {images.length > slidesPerView && (
                <Buttons>
                    <IconButton ref={navigationPrevRef} type="button">
                        <PrevArrow />
                    </IconButton>
                    <IconButton ref={navigationNextRef} type="button">
                        <NextArrow />
                    </IconButton>
                </Buttons>
            )}
        </DescriptionImagesWrapper>
    );
};
