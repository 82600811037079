import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';

export const TagWrapper = styled.div`
    ${flexCenter};
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 6px 12px;
    margin-right: 4px;
    margin-bottom: 6px;
    border-radius: 20px;
    color: var(--linkTagColor);
    background-color: var(--linkTagBackgroundColor);

    ${media.phoneBig(`
        margin-right: 8px;
    `)};
`;
