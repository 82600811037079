import { styled } from '@linaria/react';
import { BlockWrapper } from 'components/wrappers/BlockWrapper/styles';
import { media } from 'constants/styles/media';

export const UpdateBlockWrapper = styled(BlockWrapper)`
    height: fit-content;

    ${media.phoneBig(`
        flex-direction: row;
        justify-content: space-between;
    `)};
`;

export const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const UpdateTagsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;

    ${media.laptopSmall(`
        margin-top: 6px;
    `)};

    ${media.tabletSmall(`
       margin-top: 12px;
    `)};
`;
