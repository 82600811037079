import { styled } from '@linaria/react';
import { TagText } from 'components/Tag/styles';
import { TagWrapper } from 'components/wrappers/TagWrapper/styles';
import { BadgeTypeEnum } from 'types/types';
import { BadgeProps } from '.';

interface BadgeWrapperProps extends BadgeProps {}

export const BadgeWrapper = styled(TagWrapper)<BadgeWrapperProps>`
    color: ${({ type }) => (type === BadgeTypeEnum.BugFixes ? `var(--bugTagColor)` : `var(--featureTagColor)`)};
    background-color: ${({ type }) =>
        type === BadgeTypeEnum.BugFixes ? `var(--bugTagBackgroundColor)` : `var(--featureTagBackgroundColor)`};
`;

export const BadgeText = styled(TagText)``;
