import React from 'react';
import { Badge } from 'components/Badge';
import { SupplementaryText } from 'components/common/typography/SupplementaryText/styles';
import { Tag } from 'components/Tag';
import { singlePage } from 'constants/routes';
import { TagCounter } from 'components/TagCounter';
import { visibleTagsLimit } from './constants';
import { SubtitleWrapper, TagsWrapper, StyledParagraph, VersionWrapper, StyledLInk } from './styles';
import { formatDateISOString } from 'utils/usefulFunctions';
import { DescriptionTitle } from 'components/common/typography/DescriptionTitle';
import { BlockWrapper } from 'components/wrappers/BlockWrapper/styles';

export interface DescriptionBlockProps extends IVWT.Version {}

export const DescriptionBlock = ({
    id,
    title,
    version,
    date,
    description,
    tags,
    types
}: //...versionProps
DescriptionBlockProps) => {
    const wasRead = false; // TODO logic
    const hiddenTagsQuantity = tags.length + types.length - visibleTagsLimit;
    const tagCounterValue = `+${hiddenTagsQuantity} more`;
    const formattedDate = formatDateISOString(date);

    return (
        <BlockWrapper>
            <SubtitleWrapper>
                <StyledLInk to={`${singlePage}/${id}`}>
                    <DescriptionTitle wasRead={wasRead}>{title}</DescriptionTitle>
                </StyledLInk>

                <VersionWrapper>
                    <SupplementaryText>{version} Version</SupplementaryText>
                </VersionWrapper>
            </SubtitleWrapper>

            <SupplementaryText>{formattedDate}</SupplementaryText>
            {!!tags.length && (
                <TagsWrapper>
                    {types.map((type, index) => (
                        <Badge key={`${index.toString()}${type}`} type={type} />
                    ))}

                    {tags.map((tag, index) => {
                        const { _id, link } = tag;
                        return index < 4 ? <Tag key={`${index.toString()}${link}`} {...tag} /> : null;
                    })}

                    {hiddenTagsQuantity > 0 && <TagCounter value={tagCounterValue} />}
                </TagsWrapper>
            )}

            <StyledParagraph wasRead={wasRead}>{description}</StyledParagraph>
        </BlockWrapper>
    );
};
