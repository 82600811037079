import { createEvent, createStore } from 'effector';

const show = createEvent<IVWT.File[]>();
const hide = createEvent();

interface LightBoxStore {
    isVisible: boolean;
    images: IVWT.File[];
}

const initialState: LightBoxStore = {
    isVisible: false,
    images: []
};

const data = createStore<LightBoxStore>(initialState)
    .on(show, (_, images) => ({
        isVisible: true,
        images
    }))
    .on(hide, () => initialState);

export const lightBoxEvents = { show, hide };
export const lightBoxStores = { data };
