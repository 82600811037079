import { styled } from '@linaria/react';
import { blue } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { ellipsisMixin } from 'constants/styles/mixins';
import { DescriptionTitleProps } from '.';

export const SinglePageTitle = styled.h2<DescriptionTitleProps>`
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--textColor);

    ${media.phone(`
        font-size: 16px;
        line-height: 19px;
    `)};

    ${media.tabletSmall(`
        font-size: 18px;
        line-height: 21px;
    `)};
`;

export const Title = styled(SinglePageTitle)<DescriptionTitleProps>`
    opacity: ${({ wasRead }) => (wasRead ? '50%' : '100%')};
    transition: color 0.3s;
    ${ellipsisMixin};

    &:hover {
        color: ${blue};
    }
`;

export const StateIndicator = styled.div`
    background-color: ${blue};
    border-radius: 50%;
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    margin-top: 5px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-right: 20px;
`;
