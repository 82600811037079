import { styled } from '@linaria/react';
import { blue, white } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';

export const DescriptionImagesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 8px;

    ${media.phoneBig(`
        width: 125px;
        min-width: 125px;
        margin-right: 20px;
        margin-bottom: 0;
    `)};

    ${media.laptopSmall(`
        width: 208px;
        min-width: 208px;
    `)};
`;

export const StyledImage = styled.img`
    max-width: 100%;
    object-fit: contain;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    cursor: pointer;
`;

export const ImagesWrapper = styled.div`
    width: 100%;
    height: 90px;
    display: flex;
    overflow-x: auto;
    border-radius: 4px;
    border: 1px solid var(--imageBackgroundColor);
    background-color: var(--imageBackgroundColor);

    ${media.phone(`
        height: 125px;
    `)};

    ${media.phoneBig(`
        height: 110px;
    `)};

    ${media.laptopSmall(`
        height: 160px;
    `)};

    ${media.tabletSmall(`
        height: 208px;
    `)};
`;

export const Buttons = styled.div`
    width: 100%;
    ${flexCenter};
    gap: 12px;
    margin-top: 8px;
    display: none;

    ${media.phoneBig(`
        display: flex;
    `)};
`;

interface IconButtonProps {
    isLight?: boolean;
}

export const IconButton = styled.button<IconButtonProps>`
    width: 14px;
    height: 14px;
    ${flexCenter};
    ${disableDefaultButtonStyleMixin};
    color: ${({ isLight }) => (isLight ? white : blue)};
    cursor: pointer;

    &:disabled {
        color: var(--disabledIconColor);
        cursor: not-allowed;
    }
`;
