export const sizes = {
    phone: '390',
    phoneBig: '460',
    laptopSmall: '768',
    laptop: '992',
    tabletSmall: '1200',
    tablet: '1380',
    desktop: '1440'
};

type KeysType = keyof typeof sizes;

// TODO: any
type MediaFunction = (styles: string) => any;

export const media = (Object.keys(sizes) as KeysType[]).reduce((acc, label) => {
    acc[label] = (styles: string) => `
        @media (min-width: ${sizes[label]}px) {
            ${styles}
        }
    `;

    return acc;
}, {} as { [key in KeysType]: MediaFunction });
