import { styled } from '@linaria/react';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import { lightWhite, white } from 'constants/styles/colors';
import { media } from 'constants/styles/media';

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.laptop(`
        padding-bottom: 40px;
        justify-content: flex-end;
    `)};
`;

export const TopSliderWrapper = styled.div`
    max-width: 100%;
    max-height: 70vh;
    ${flexCenter};

    ${media.laptop(`
        padding-bottom: 40px;
        max-height: calc(70vh - 40px);
    `)};
`;

export const TopSliderButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    box-sizing: content-box;
    width: 40px;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
    ${flexCenter};
    color: ${white};
    cursor: pointer;
    display: none;

    &:disabled {
        color: ${lightWhite};
        cursor: not-allowed;
    }

    ${media.laptopSmall(`
        display: flex;
    `)};
`;

export const TopSlider = styled.div`
    width: 100%;
    max-width: 100%;
    height: 280px;
    max-height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;

    ${media.phone(`
        height: 360px;
    `)};

    ${media.phoneBig(`
        padding-left: 24px;
        padding-right: 24px;
    `)};

    ${media.laptopSmall(`
        max-width: 600px;
        height: 480px;
        padding-left: 0;
        padding-right: 0;
    `)};

    ${media.tabletSmall(`
        max-width: 780px;
        height: 560px;
    `)};

    ${media.tablet(`
        max-width: 980px;
        height: 690px;
    `)};
`;

export const TopImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
`;

export const TopStyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
    cursor: pointer;
`;

export const ThumbsSliderWrapper = styled.div`
    width: 100%;
    max-height: 30vh;
    padding-top: 20px;

    ${media.phoneBig(`
        padding-top: 40px;
    `)};
`;

export const ThumbsSwiperSlideStyles = {
    common: {
        maxHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    desktop: {
        width: '208px',
        height: '140px'
    },
    mobile: {
        width: '100px',
        height: '60px'
    }
};

interface ThumbsStyledImageProps {
    url: string;
}

export const ThumbsStyledImage = styled.div<ThumbsStyledImageProps>`
    width: 100%;
    height: 100%;
    background-image: ${({ url }) => `url(${url})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
`;

export const FixedWrapper = styled.div`
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1002;
`;
