import React, { FC } from 'react';
import { IsSinglePage, WasRead } from 'types/versions';
import { SinglePageTitle, StateIndicator, Title, TitleWrapper } from './styles';

export interface DescriptionTitleProps extends WasRead, IsSinglePage {}

export const DescriptionTitle: FC<DescriptionTitleProps> = ({ wasRead = false, children, isSinglePage = false }) => (
    <TitleWrapper>
        {isSinglePage ? (
            <SinglePageTitle>{children}</SinglePageTitle>
        ) : (
            <>
                <Title wasRead={wasRead}>{children} </Title>
                {!wasRead && <StateIndicator />}
            </>
        )}
    </TitleWrapper>
);
