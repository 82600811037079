export enum ThemeEnum {
    Dark = 'dark',
    Light = 'light'
}

export type Theme = ThemeEnum.Light | ThemeEnum.Dark;

export type Noop = () => void;

export type AlertStatusType = 'info' | 'success' | 'error' | undefined;

export type BadgeType = BadgeTypeEnum.BugFixes | BadgeTypeEnum.Features;

export enum BadgeTypeEnum {
    BugFixes = 'bugFixes',
    Features = 'features'
}
