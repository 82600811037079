import React from 'react';
import { IconWrapper, StyledLink, TagText } from './styles';
import { LinkArrowIcon } from 'components/common/icons';
import { TagWrapper } from 'components/wrappers/TagWrapper/styles';

export const Tag = ({ tag, link /*, _id*/ }: IVWT.Tag) => (
    <TagWrapper>
        <StyledLink href={link} rel="noopener noreferrer" target="_blank">
            <TagText>{tag}</TagText>
            <IconWrapper>
                <LinkArrowIcon />
            </IconWrapper>
        </StyledLink>
    </TagWrapper>
);
