import React from 'react';
import { StyledClickableWrapper } from './styles';
import { NoopClick } from 'types/global';
import { Disabled } from 'types/styles';
import { GreaterThanIcon, LessThanIcon } from 'components/common/icons';

export interface ButtonProps extends NoopClick, Disabled {}

export const PaginationLeftButton = ({ onClick, disabled }: ButtonProps) => (
    <StyledClickableWrapper disabled={disabled} onClick={onClick}>
        <LessThanIcon />
    </StyledClickableWrapper>
);

export const PaginationRightButton = ({ onClick, disabled }: ButtonProps) => (
    <StyledClickableWrapper disabled={disabled} onClick={onClick}>
        <GreaterThanIcon />
    </StyledClickableWrapper>
);
