import format from 'date-fns/format';

export const dateBadgeFormat = 'd MMMM, yyyy';

export const formatDateISOString = (dateISOString?: string) => {
    const date = dateISOString && Date.parse(dateISOString);

    return date ? format(date, dateBadgeFormat) : '';
};

export const addMultiLineEllipsis = (linesQuantity: number) =>
    `overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: ${linesQuantity};`;
