import React from 'react';
import { BadgeType, BadgeTypeEnum } from 'types/types';
import { bugBadgeText, featureBadgeText } from './constants';
import { BadgeText, BadgeWrapper } from './style';

export interface BadgeProps {
    type: BadgeType;
}

export const Badge = ({ type }: BadgeProps) => {
    const text = type === BadgeTypeEnum.BugFixes ? bugBadgeText : featureBadgeText;
    return (
        <BadgeWrapper type={type}>
            <BadgeText>{text}</BadgeText>
        </BadgeWrapper>
    );
};
