import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import { Active } from 'types/styles';

export const PageIndexButton = styled.button<Active>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 4px;
    color: ${({ active }) => (active ? `var(--activeColor)` : `var(--inActiveColor)`)};
    background-color: ${({ active }) => (active ? `var(--pageIndexButtonActiveColor)` : '')};

    &:hover {
        background-color: var(--pageIndexButtonHoverColor);
    }

    ${media.phone(`
        font-size: 16px;
        line-height: 18px;
        width: 32px;
        height: 32px;
    `)};
`;

export const PaginationContainer = styled.div`
    ${flexCenter};
    height: 56px;

    ${media.phone(`
       height: 72px;
    `)};

    ${media.phoneBig(`
        height: 80px;
    `)};
`;
