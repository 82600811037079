import { styled } from '@linaria/react';
import { Paragraph } from 'components/common/typography/Paragraph/styles';
import { media } from 'constants/styles/media';
import { Link } from 'react-router-dom';
import { addMultiLineEllipsis } from 'utils/usefulFunctions';

export const SubtitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 6px;
`;

export const TagsWrapper = styled.div`
    width: 100%;
    max-height: 70px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    overflow: auto;

    ${media.laptopSmall(`
        margin-top: 6px;
    `)};

    ${media.tabletSmall(`
       margin-top: 12px;
    `)};
`;

export const StyledParagraph = styled(Paragraph)`
    width: 100%;
    height: fit-content;
    ${addMultiLineEllipsis(3)};

    ${media.phone(`
       max-height: 43px;
    `)};

    ${media.tabletSmall(`
       max-height: 54px;
    `)};
`;

export const VersionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledLInk = styled(Link)`
    min-width: 150px;
`;
