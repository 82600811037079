import { IVWTInitParameters } from 'types/plugin';

export const defaultPageIndex = 1;
export const defaultPageLimit = 3;
export const defaultTotalPages = 1;

export const defaultQueryValues: IVWT.DataQueryRequest = {
    page: defaultPageIndex,
    limit: defaultPageLimit
};

export const defaultInitValues: IVWTInitParameters = {
    selector: '',
    projectId: '',
    apiKey: ''
};
