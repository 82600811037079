import React, { useEffect, useRef, useState, MouseEvent } from 'react';
import { useStore } from 'effector-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Zoom, FreeMode, Thumbs, Swiper as SwiperType } from 'swiper';
import { ModalBackground } from 'components/wrappers/ModalWrapper/styles';
import { CrossIcon, NextArrow, PrevArrow } from 'components/common/icons';
import { IconButton } from 'components/blocks/DescriptionImages/styles';
import { lightBoxEvents, lightBoxStores } from 'stores/lightbox';
import { sizes } from 'constants/styles/media';
import { useMobile } from 'hooks/useMobile';
import {
    FixedWrapper,
    MainWrapper,
    ThumbsSliderWrapper,
    ThumbsStyledImage,
    ThumbsSwiperSlideStyles,
    TopImageWrapper,
    TopSlider,
    TopSliderButton,
    TopSliderWrapper,
    TopStyledImage
} from './styles';
import { defaultNavigation, slidesPerView } from './constants';
import 'swiper/css';
import 'swiper/css/zoom';

const { hide } = lightBoxEvents;
const { data } = lightBoxStores;

interface NavigationProperties {
    prevEl: HTMLButtonElement | null;
    nextEl: HTMLButtonElement | null;
}

export const LightBox = () => {
    const componentRef = useRef<HTMLDivElement>(null);
    const navigationPrevRef = useRef<HTMLButtonElement>(null);
    const navigationNextRef = useRef<HTMLButtonElement>(null);
    const [navigation, setNavigation] = useState<NavigationProperties>(defaultNavigation);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null | undefined>(null);
    const { isVisible, images } = useStore(data);
    const isMobile = useMobile(+sizes.laptopSmall);

    useEffect(() => {
        if (navigationPrevRef.current && navigationNextRef.current) {
            setNavigation({
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
            });
        }
    }, [isVisible]);

    const onBackgroundClick = (event: MouseEvent) => {
        if (componentRef.current === event.target) {
            hide();
        }
    };

    return (
        <ModalBackground zIndex="1001" onClick={onBackgroundClick}>
            <MainWrapper ref={componentRef}>
                <TopSliderWrapper>
                    {images.length > slidesPerView && (
                        <TopSliderButton ref={navigationPrevRef} type="button">
                            <PrevArrow height="40px" width="40px" />
                        </TopSliderButton>
                    )}

                    <TopSlider>
                        <FixedWrapper>
                            <IconButton isLight onClick={() => hide()}>
                                <CrossIcon />
                            </IconButton>
                        </FixedWrapper>

                        <Swiper
                            zoom
                            modules={[Zoom, Navigation, FreeMode, Thumbs]}
                            navigation={navigation}
                            slidesPerView={slidesPerView}
                            spaceBetween={10}
                            style={{ height: '100%' }}
                            thumbs={{ swiper: thumbsSwiper }}
                        >
                            {images.map(({ id, url, name }) => (
                                <SwiperSlide key={id}>
                                    <TopImageWrapper className="swiper-zoom-container">
                                        <TopStyledImage alt={name} src={url} />
                                    </TopImageWrapper>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </TopSlider>

                    {images.length > slidesPerView && (
                        <TopSliderButton ref={navigationNextRef} type="button">
                            <NextArrow height="40px" width="40px" />
                        </TopSliderButton>
                    )}
                </TopSliderWrapper>

                <ThumbsSliderWrapper>
                    <Swiper
                        freeMode
                        watchSlidesProgress
                        breakpoints={{
                            [sizes.phoneBig]: {
                                slidesOffsetAfter: 40,
                                slidesOffsetBefore: 40
                            }
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        slidesOffsetAfter={16}
                        slidesOffsetBefore={16}
                        slidesPerView="auto"
                        spaceBetween={10}
                        style={{
                            maxHeight: '100%'
                        }}
                        onSwiper={setThumbsSwiper}
                    >
                        {images.map(({ id, url }) => (
                            <SwiperSlide
                                key={id}
                                style={{
                                    ...ThumbsSwiperSlideStyles.common,
                                    ...ThumbsSwiperSlideStyles[isMobile ? 'mobile' : 'desktop']
                                }}
                            >
                                <ThumbsStyledImage url={url} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </ThumbsSliderWrapper>
            </MainWrapper>
        </ModalBackground>
    );
};
