import { styled } from '@linaria/react';
import { modalWrapperBackground } from 'constants/styles/colors';
import { modalBorderRadius } from 'constants/styles/sizes';
import { absoluteCenterAlignmentMixin, flexCenter } from 'constants/styles/mixins';
import { media } from 'constants/styles/media';
import { IsSinglePage } from 'types/versions';
import { Link } from 'react-router-dom';

interface ModalBackgroundProps {
    zIndex?: string;
}

export const ModalBackground = styled.div<ModalBackgroundProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${modalWrapperBackground};
    z-index: ${({ zIndex }) => zIndex || '1000'};
    overflow: auto;
`;

export const Modal = styled.div`
    ${absoluteCenterAlignmentMixin};
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    height: 478px;
    background-color: var(--modalBackgroundColor);
    border-radius: ${modalBorderRadius};
    overflow: auto;
    z-index: 2;

    ${media.phone(`
        height: 685px;
    `)};

    ${media.phoneBig(`
        width: calc(100% - 48px);
    `)};

    ${media.laptopSmall(`
        width: 680px;
    `)};

    ${media.tabletSmall(`
        width: 780px;
        height: 760px;
    `)};
`;

export const ModalContentWrapper = styled.div<IsSinglePage>`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    padding: 16px;
    padding-bottom: 0px;
    overflow: auto;
    margin-bottom: ${({ isSinglePage }) => (isSinglePage ? '0' : '56px')};

    ${media.phone(`
      margin-bottom: 72px;
    `)};

    ${media.phoneBig(`
        padding-right: 18px;
        margin-bottom: 80px;
    `)};

    ${media.laptopSmall(`
       padding: 20px;
    `)};

    ${media.tabletSmall(`
        width: calc(100% - 24px);
        padding: 20px 24px;
        margin-right: 24px;
    `)};
`;
//TODO add condition to media
// ${media.phone(`
// margin-bottom: ${({ isSinglePage }) => (isSinglePage ? '0' : '72px')};
// `)};

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 2px solid var(--horizontalLineColor);

    ${media.phone(`
        height: 61px;
        padding: 5px;
    `)};

    ${media.phoneBig(`
        height: 77px;
        padding: 10px;
    `)};
`;

export const PaginationWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    ${flexCenter};
    background-color: var(--modalBackgroundColor);
    box-shadow: 0px -8px 12px var(--paginationShadowColor);
    border-bottom-right-radius: ${modalBorderRadius};
    border-bottom-left-radius: ${modalBorderRadius};
`;

export const StyledLink = styled(Link)`
    ${flexCenter};
    color: var(--backArrowIconColor);
    padding: 15px;
    transition: color 0.3s;

    &:hover {
        color: var(--closeIconHoverColor);
    }
`;

export const ErrorWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    ${flexCenter};
    flex-direction: column;
`;

export const ErrorText = styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--errorModalTextColor);
    margin-top: 20px;
    margin-bottom: 24px;

    ${media.phone(`
        font-size: 18px;
        line-height: 25px;
        margin-top: 24px;
        margin-bottom: 32px;
    `)};

    ${media.phoneBig(`
        font-size: 20px;
        line-height: 28px;
    `)};

    ${media.laptopSmall(`
        font-size: 22px;
        line-height: 26px;
        margin-top: 32px;
        margin-bottom: 40px;
    `)};

    ${media.tabletSmall(`
        font-size: 24px;
        line-height: 28px;
    `)};
`;

export const Span = styled.span`
    white-space: nowrap;
`;

export const AbsoluteWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;

    ${media.phoneBig(`
        top: 15px;
        right: 10px;
    `)};
`;

export const ImageWrapper = styled.div`
    width: 160px;
    height: 142px;

    ${media.phone(`
        width: 230px;
        height: 204px;
    `)};
`;
