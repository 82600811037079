import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ivwtBaseURL } from 'constants/services';

const ivwtAxiosInstance = axios.create();

ivwtAxiosInstance.defaults.baseURL = ivwtBaseURL;
ivwtAxiosInstance.defaults.method = 'GET';
ivwtAxiosInstance.interceptors.response.use(
    config => config.data,
    config => Promise.reject(config?.response?.data ?? config)
);

// eslint-disable-next-line import/no-anonymous-default-export
export default <T = void>(config: AxiosRequestConfig) => {
    const request: AxiosPromise<T> = ivwtAxiosInstance({
        ...config,
        headers: {}
    });

    return request as any as Promise<T>;
};
