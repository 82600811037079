import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { useParams } from 'react-router';
import { UpdateDescriptionBlock } from 'components/blocks/UpdateDescriptionBlock';
import { SupplementaryText } from 'components/common/typography/SupplementaryText/styles';
import { ModalWrapper } from 'components/wrappers/ModalWrapper';
import { LightBox } from 'components/LightBox';
import { projectEffects, projectStores } from 'stores/project';
import { lightBoxStores } from 'stores/lightbox';
import { formatDateISOString } from 'utils/usefulFunctions';
import { SinglePageTitle, SinglePageTitleWrapper, StyledBlockWrapper, StyledParagraph } from './styles';
import { defaultQueryValues } from 'constants/defaults';

const { projectData } = projectStores;
const { getProjectData } = projectEffects;
const { data } = lightBoxStores;

export const Single = () => {
    const { versionId } = useParams();
    const { items } = useStore(projectData);
    const { isVisible, images } = useStore(data);
    const version = items?.find(version => version.id === versionId);
    const versionUpdates = version?.updateItems;
    const title = version?.title;
    const versionNumber = version?.version;
    const description = version?.description;
    const formattedDate = formatDateISOString(version?.date);

    useEffect(() => {
        !items && getProjectData(defaultQueryValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ModalWrapper isSinglePage>
                <StyledBlockWrapper>
                    <SinglePageTitleWrapper>
                        <SinglePageTitle>{title}</SinglePageTitle>
                        <SupplementaryText>{versionNumber} Version</SupplementaryText>
                    </SinglePageTitleWrapper>

                    <SupplementaryText>{formattedDate}</SupplementaryText>

                    <StyledParagraph>{description}</StyledParagraph>
                </StyledBlockWrapper>

                {versionUpdates?.map(update => (
                    <UpdateDescriptionBlock key={update.id} {...update} />
                ))}
            </ModalWrapper>

            {isVisible && images.length > 0 && <LightBox />}
        </>
    );
};
