export const threeDots = '...';
export const paginationPagesLimit = 6;
export const paginationPagesLimitExceptFirstAndLast = paginationPagesLimit - 2;
export const threeDotsRightPaginationPosition = paginationPagesLimit - 1;
export const previousPagesQuantity = 1;
export const nextPagesQuantity = 2;

export const createPageNumbersArrayBetweenFirstAndLast = (totalPages: number) => {
    let page = 0;
    const pageNumbersArray = Array.from({ length: totalPages }, () => {
        ++page;
        return page;
    }).slice(1, -1);

    return pageNumbersArray;
};
