import React from 'react';
import { TagCounterWrapper } from './styles';
import { TagText } from 'components/Tag/styles';

interface TagCounterProps {
    value: string;
}

export const TagCounter = ({ value }: TagCounterProps) => (
    <TagCounterWrapper>
        <TagText>{value}</TagText>
    </TagCounterWrapper>
);
