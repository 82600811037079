import { css } from '@linaria/core';

// * common
export const black = '#000000';
export const white = '#ffffff';
export const blue = '#3360ff';
export const orange = '#ff8743';
export const lightBlue = 'rgba(51, 96, 255, 0.1)';
export const lightOrange = 'rgba(255, 135, 67, 0.1)';
export const lightWhite = 'rgba(255, 255, 255, 0.2)';

export const modalWrapperBackground = 'rgba(0, 0, 0, 0.6)';

export const lightTheme = css`
    --modalBackgroundColor: ${white};
    --textColor: ${black};

    --horizontalLineColor: rgba(0, 0, 0, 0.03);

    --imageBackgroundColor: #f2f2f2;

    --bugTagColor: ${orange};
    --featureTagColor: ${blue};
    --linkTagColor: ${black};
    --linkTagIconColor: ${black};

    --bugTagBackgroundColor: ${lightOrange};
    --featureTagBackgroundColor: ${lightBlue};
    --linkTagBackgroundColor: rgba(0, 0, 0, 0.05);
    --tagCounterColor: rgba(0, 0, 0, 0.25);

    --backArrowIconColor: ${black};

    --closeIconColor: rgba(0, 0, 0, 0.25);
    --closeIconHoverColor: rgba(51, 96, 255, 0.5);

    --paginationIconDisabledColor: rgba(0, 0, 0, 0.25);
    --paginationIconColor: rgba(0, 0, 0, 0.8);
    --paginationShadowColor: rgba(0, 0, 0, 0.05);

    --activeColor: ${blue};
    --inActiveColor: rgba(0, 0, 0, 0.8);

    --pageIndexButtonActiveColor: ${lightBlue};
    --pageIndexButtonHoverColor: rgba(51, 96, 255, 0.3);

    --scrollBarColor: rgba(0, 0, 0, 0.15);

    --errorModalTextColor: rgba(25, 33, 41, 0.5);
    --warningSignColor: #78a9e2;
    --warningSignBackgroundColor: #e1f0ff;

    --disabledIconColor: rgba(25, 33, 41, 0.35);

    --skeletonBaseColor: #ebebeb;
    --skeletonHighlightColor: #f5f5f5;
`;

export const darkTheme = css`
    --modalBackgroundColor: #1e1f2e;
    --textColor: ${white};

    --horizontalLineColor: rgba(255, 255, 255, 0.03);

    --imageBackgroundColor: rgba(255, 255, 255, 0.15);

    --bugTagColor: ${orange};
    --featureTagColor: ${blue};
    --linkTagColor: ${white};
    --linkTagIconColor: ${white};

    --bugTagBackgroundColor: ${lightOrange};
    --featureTagBackgroundColor: ${lightBlue};
    --linkTagBackgroundColor: rgba(255, 255, 255, 0.05);
    --tagCounterColor: rgba(255, 255, 255, 0.25);

    --backArrowIconColor: ${white};

    --closeIconColor: rgba(255, 255, 255, 0.25);
    --closeIconHoverColor: rgba(255, 255, 255, 0.6);

    --paginationIconDisabledColor: rgba(255, 255, 255, 0.25);
    --paginationIconColor: rgba(255, 255, 255, 0.8);
    --paginationShadowColor: rgba(0, 0, 0, 0.25);

    --activeColor: ${blue};
    --inActiveColor: rgba(255, 255, 255, 0.8);

    --pageIndexButtonActiveColor: ${lightBlue};
    --pageIndexButtonHoverColor: rgba(51, 96, 255, 0.4);

    --scrollBarColor: rgba(255, 255, 255, 0.15);

    --errorModalTextColor: rgba(255, 255, 255, 0.5);
    --warningSignColor: #1e1f2e;
    --warningSignBackgroundColor: rgba(255, 255, 255, 0.25);

    --disabledIconColor: rgba(255, 255, 255, 0.8);

    --skeletonBaseColor: #272836;
    --skeletonHighlightColor: #2d2e3d;
`;
