import { createEvent, createStore, attach } from 'effector';
import { API } from 'services';
import { initStores } from './init';

const { initialParametersStore } = initStores;

export interface ErrorInterface {
    data?: string;
    message?: string;
    err?: string;
}

const setErrors = createEvent<ErrorInterface>();

const getProjectData = attach({
    source: initialParametersStore,
    effect: async (initParameters, queryValues: IVWT.DataQueryRequest) => {
        try {
            const { apiKey, projectId } = initParameters;
            const response = await API.project.initProjectAndGetData({ apiKey, projectId, ...queryValues });

            return response;
        } catch (error: any) {
            const { data, message, err, status } = error;

            data && console.error('data: ', data);
            status && console.error('status: ', status);
            message && console.error('message: ', message);
            err && console.error('err: ', err);

            setErrors(error);
        }
    }
});

//******* stores

const projectData = createStore<IVWT.InitProjectResponse>({}).on(getProjectData.doneData, (_, newState) => newState);

const errors = createStore<ErrorInterface>({ data: '', message: '', err: '' }).on(setErrors, (_, newState) => newState);

//******* exports

export const projectEvents = { setErrors };
export const projectEffects = {
    getProjectData
};
export const projectStores = { projectData, errors };
