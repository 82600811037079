import { styled } from '@linaria/react';
import { media } from 'constants/styles/media';
import { WasRead } from 'types/versions';

interface ParagraphProps extends WasRead {}

export const Paragraph = styled.p<ParagraphProps>`
    font-size: 10px;
    line-height: 12px;
    text-align: justify;
    margin: 0;
    margin-top: 8px;
    color: var(--textColor);
    opacity: ${({ wasRead }) => (wasRead ? '60%' : '80%')};

    ${media.phone(`
        font-size: 12px;
        line-height: 14px;
    `)};

    ${media.tabletSmall(`
        font-size: 14px;
        line-height: 17px;
    `)};
`;
