import { PaginationLeftButton, PaginationRightButton } from 'components/common/buttons/PaginationButtons';
import React from 'react';
import {
    createPageNumbersArrayBetweenFirstAndLast,
    nextPagesQuantity,
    paginationPagesLimit,
    paginationPagesLimitExceptFirstAndLast,
    previousPagesQuantity,
    threeDots,
    threeDotsRightPaginationPosition
} from './constants';
import { PageIndexButton, PaginationContainer } from './styles';
interface PagerProps {
    activeIndex: number;
    totalPages: number;
    onChange: (current: number) => void;
}

const SmallPager = ({ activeIndex, totalPages, onChange }: PagerProps) => {
    const pageNumbersArray = createPageNumbersArrayBetweenFirstAndLast(totalPages);

    return (
        <>
            {pageNumbersArray.map(pageNumber => (
                <PageIndexButton
                    key={pageNumber.toString()}
                    active={pageNumber === activeIndex}
                    onClick={() => onChange(pageNumber)}
                >
                    {pageNumber}
                </PageIndexButton>
            ))}
        </>
    );
};

interface PagesProps extends Omit<PagerProps, 'totalPages'> {
    pagesArray: number[];
    threeDotsPosition: number;
}

const Pages = ({ pagesArray, activeIndex, threeDotsPosition, onChange }: PagesProps) => (
    <>
        {pagesArray.map(pageNumber => (
            <PageIndexButton
                key={pageNumber.toString()}
                active={activeIndex === pageNumber}
                onClick={() => onChange(pageNumber)}
            >
                {pageNumber === threeDotsPosition ? threeDots : pageNumber}
            </PageIndexButton>
        ))}
    </>
);

const BigPager = ({ activeIndex, totalPages, onChange }: PagerProps) => {
    const pageNumbersArray = createPageNumbersArrayBetweenFirstAndLast(totalPages);
    const threeDotsLeftPaginationPosition = totalPages - paginationPagesLimitExceptFirstAndLast;
    const startPages = pageNumbersArray.filter(pageNumber => pageNumber <= threeDotsRightPaginationPosition);
    const endPages = pageNumbersArray.filter(pageNumber => pageNumber >= threeDotsLeftPaginationPosition);
    const middlePages = pageNumbersArray.filter(
        pageNumber => pageNumber >= activeIndex - previousPagesQuantity && pageNumber <= activeIndex + nextPagesQuantity
    );

    return (
        <>
            {activeIndex < threeDotsRightPaginationPosition ? (
                <Pages
                    activeIndex={activeIndex}
                    pagesArray={startPages}
                    threeDotsPosition={threeDotsRightPaginationPosition}
                    onChange={onChange}
                />
            ) : activeIndex > threeDotsLeftPaginationPosition ? (
                <Pages
                    activeIndex={activeIndex}
                    pagesArray={endPages}
                    threeDotsPosition={threeDotsLeftPaginationPosition}
                    onChange={onChange}
                />
            ) : (
                middlePages.map((pageNumber, index, arr) => (
                    <PageIndexButton
                        key={pageNumber.toString()}
                        active={activeIndex === pageNumber}
                        onClick={() => onChange(pageNumber)}
                    >
                        {index === 0 || index === arr.length - 1 ? threeDots : pageNumber}
                    </PageIndexButton>
                ))
            )}
        </>
    );
};

export interface PaginationProps {
    currentIndex: number;
    totalPages: number;
    onIndexChange: (current: number) => void;
}

export const Pagination = ({ currentIndex, totalPages, onIndexChange }: PaginationProps) => (
    <PaginationContainer>
        <PaginationLeftButton disabled={currentIndex === 1} onClick={() => onIndexChange(currentIndex - 1)} />
        <PageIndexButton active={currentIndex === 1} onClick={() => onIndexChange(1)}>
            1
        </PageIndexButton>
        {totalPages <= paginationPagesLimit ? (
            <SmallPager activeIndex={currentIndex} totalPages={totalPages} onChange={onIndexChange} />
        ) : (
            <BigPager activeIndex={currentIndex} totalPages={totalPages} onChange={onIndexChange} />
        )}

        <PageIndexButton active={currentIndex === totalPages} onClick={() => onIndexChange(totalPages)}>
            {totalPages}
        </PageIndexButton>

        <PaginationRightButton disabled={currentIndex === totalPages} onClick={() => onIndexChange(currentIndex + 1)} />
    </PaginationContainer>
);
