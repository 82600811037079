import { createEvent, createStore } from 'effector';

const setIsFirstRequestToFalse = createEvent();
const setIsFirstRequestToTrue = createEvent();

const isFirstRequest = createStore<boolean>(true)
    .on(setIsFirstRequestToFalse, () => false)
    .on(setIsFirstRequestToTrue, () => true);

export const isFirstRequestEvents = { setIsFirstRequestToFalse, setIsFirstRequestToTrue };
export const isFirstRequestStores = { isFirstRequest };
